import { shouldEmbed } from './EmbeddedLinkRenderer';
const ParagraphSkipImage = ({ node, className, children, ...props }) => {
  const hasImage = !!node?.children.find(
    (child) => typeof child === 'object' && child?.tagName === 'img'
  );
  const hasEmbed = !!node?.children.find(
    (child) =>
      typeof child === 'object' &&
      child?.tagName === 'a' &&
      shouldEmbed(child.properties)
  );
  if (hasImage || hasEmbed) {
    return children;
  }
  return (
    <p {...props} className={className}>
      {children}
    </p>
  );
};

export default ParagraphSkipImage;
