import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { H1 } from '../../elements/Text';
import React from 'react';
import { ACTIVITY_STEP } from '../Content';
import { QuestionProps } from '.';
import {
  HeaderAction,
  BottomSpacer,
  EmptyContainer,
  Header,
  SplitScreenQuestion,
  getQuestionText,
} from './common';
import { NAVBAR_HEIGHT } from '../../Navbar';
import {
  SheetsIFrame,
  getGoogleSheetsCopyUrl,
  getSheetsEmbedPreviewUrl,
} from '../../Embeds/GoogleSheetsEmbed';
import { MultiLineInput } from '../../elements/Input';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';

export default function FreeTextSheetsEmbed({
  question,
  answer,
}: QuestionProps) {
  const [activity, dispatch] = React.useContext(ActivityContext);

  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const onFreeTextGoogleSheetsExternalQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_GOOGLE_SHEETS_EXTERNAL_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const openSheetInNewTab = () =>
    window.open(
      getGoogleSheetsCopyUrl(question?.google_sheets_link ?? ''),
      '_blank'
    );

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <SplitScreenQuestion>
      <>
        <ReadableContainer>
          <H1>Exercise {qIndex + 1}</H1>
          {!!mentorComment && <MentorReview comment={mentorComment} />}
        </ReadableContainer>
        {/* @ts-expect-error - Markdown component is not typed properly */}
        <ReadableMarkdown md={getQuestionText(question)} />
        <MultiLineInput
          maxRows={10}
          value={answer.value ?? ''}
          placeholder="Type your answer here"
          onChange={(e) =>
            onFreeTextGoogleSheetsExternalQuestionTextChange(e.target.value)
          }
        />
        <BottomSpacer />
      </>
      <EmptyContainer>
        <Header text="Use the Google Sheet embed to answer the question.">
          <>
            <HeaderAction onClick={openSheetInNewTab}>
              Open in a new tab
            </HeaderAction>
          </>
        </Header>
        <SheetsIFrame
          style={{ borderRadius: 0 }}
          src={getSheetsEmbedPreviewUrl(question.google_sheets_link)}
          title="Sheets Embed"
          height={`calc(100vh - 3 * ${NAVBAR_HEIGHT}px - 4px) `}
          width={'100%'}
          // @ts-expect-error - LookerIframe is not typed properly
          isClickable
        />
      </EmptyContainer>
    </SplitScreenQuestion>
  );
}
