import styled, { useTheme } from 'styled-components';
import { prop } from 'styled-tools';

import ActivityCard from './ActivityCard';

const DashboardActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
  margin-bottom: 102px;
`;

const MissionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  @media screen and (max-width: 1279px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
  column-gap: 32px;
  row-gap: 8px;
`;

const MissionHeader = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 36px;
  text-decoration: none;
  margin-top: 20px !important;
  padding-bottom: 14px !important;
  color: ${prop('theme.colors.textRegular')};
`;

const GridMissionColumn = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 222px;
  max-width: 280px;

  @media only screen and (min-width: 960px) and (max-width: 1365px) {
    min-width: 180px;
    max-width: 236px;
  }
`;

const GridActivitiesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 2px;
`;

export default function DashboardGrid({
  missions,
  getActivityUrl,
  isHighlightingOutstanding,
}) {
  const missionsByPart = Object.values(
    missions.reduce((acc, mission) => {
      if (!acc[mission.week.index]) {
        acc[mission.week.index] = [];
      }
      acc[mission.week.index].push(mission);
      return acc;
    }, {})
  );

  return (
    <DashboardActivitiesWrapper>
      {missionsByPart.map((missions, index) => (
        <PartGrid
          key={`part-${index}`}
          missions={missions}
          getActivityUrl={getActivityUrl}
          isHighlightingOutstanding={isHighlightingOutstanding}
        />
      ))}
    </DashboardActivitiesWrapper>
  );
}

function PartGrid({ missions, getActivityUrl, isHighlightingOutstanding }) {
  const part = missions[0].week;
  const theme = useTheme();

  return (
    <div>
      <h3
        style={{
          marginTop: '52px',
          color: theme.colors.textRegular,
          marginBottom: '4px',
        }}
        className="dim-content"
      >
        Part {part.index + 1}
      </h3>
      <MissionsGrid>
        {missions.map((mission) => (
          <GridMissionColumn key={`mission-column-${mission.slug}`}>
            <MissionHeader className="dim-content">
              {mission.index + 1}
            </MissionHeader>
            <GridActivitiesColumn>
              {mission.activities.map((activity, activityIndex) => (
                <ActivityCard
                  key={`activity-card-${activity.slug}`}
                  isHighlightingOutstanding={isHighlightingOutstanding}
                  href={getActivityUrl(mission, activity)}
                  isFirstActivityInMission={activityIndex === 0}
                  isLastActivityInMission={
                    activityIndex === mission.activities.length - 1
                  }
                  activity={activity}
                />
              ))}
            </GridActivitiesColumn>
          </GridMissionColumn>
        ))}
      </MissionsGrid>
    </div>
  );
}
