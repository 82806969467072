import isEmpty from 'lodash.isempty';
import { ActivityStepType } from '..';
import { ACTIVITY_STEP } from '../../../components/ActivityPage/Content';
import { ActivityPayload } from '../../../hydra';

export default function getActivityInitialStep(
  activity: ActivityPayload
): ActivityStepType {
  const lastCompletedQuestionIndex = activity.exercise.questions.reduceRight(
    (acc, _, qIndex) => {
      if (isEmpty((activity.latest_submission?.solutions ?? [])[qIndex])) {
        return qIndex - 1;
      }
      return acc;
    },
    -1
  );

  if (
    ![-1, activity.exercise.questions.length, -1].includes(
      lastCompletedQuestionIndex
    )
  ) {
    return {
      type: ACTIVITY_STEP.QUESTION,
      questionIndex: lastCompletedQuestionIndex + 1,
      questionSlug:
        activity.exercise.questions[lastCompletedQuestionIndex + 1]?.slug,
    };
  }

  return {
    type: ACTIVITY_STEP.LESSON,
  };
}
