import { useActor } from '@xstate/react';
import React from 'react';
import styled from 'styled-components';
import { P, PSmall } from '../../elements/Text';
import SoloOnboardingContext from '../context';
import { EVENTS } from '../state-machine';
import { CTAButton, Header, Label, LargeQuote, SubHeader } from './common';
import * as Analytics from '../../../analytics';
import { TOPICS } from '../config';

const PageWrapper = styled.div`
  display: block;
  width: 100%;
  overflow: auto;
  padding-top: 72px;
  padding-bottom: 72px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin: 0 auto;
  max-width: ${({ theme }) => theme.dimensions.readableColumnWidth};

  @media only screen and (max-width: 600px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`;

const Checkmark = styled.i.attrs({ className: 'fas fa-check' })`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
`;

const QuoteWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.inputBackground};
  padding: 32px;
`;
export function BenefitsContent({
  onNext,
  shouldShowTrial,
  quote,
}: {
  onNext: () => void;
  shouldShowTrial: boolean;
  quote?: string;
}) {
  return (
    <PageWrapper>
      <Column style={{ gap: 32 }}>
        <Section>
          <Header>Your tailored program is ready!</Header>
          <Column>
            <Row>
              <Checkmark />
              <Label textAlign="left">Understand must-know concepts</Label>
            </Row>
            <Row>
              <Checkmark />
              <Label textAlign="left">Practice essential techniques</Label>
            </Row>
            <Row>
              <Checkmark />
              <Label textAlign="left">Solve practical problems</Label>
            </Row>
          </Column>
        </Section>
        <QuoteWrapper>
          <Section>
            <HeroQuote quote={quote} />
          </Section>
        </QuoteWrapper>
        <Section>
          <SubHeader style={{ marginTop: 52 }}>How does it work?</SubHeader>
          <Label>
            Your program combines state of the art <b>interactive exercises</b>,
            with unlimited <b>AI coaching</b> —{' '}
            <b>all fully customised to you</b>.
          </Label>
          <BenefitsGrid />
          <FrobesQuote style={{ marginTop: 20 }} />
          {shouldShowTrial && (
            <>
              <SubHeader style={{ marginTop: 20 }}>
                How to use <i>your</i> free trial
              </SubHeader>
              <Label>
                You get full access to your program today, with a 7-day trial
                period. Cancel anytime during the trial without charge.
                <b>We'll remind you 2 days before your trial ends.</b>
              </Label>
              <FreeTrialIllustration />
            </>
          )}

          <SubHeader style={{ marginTop: 20 }}>
            Take control of <i>your</i> goals
          </SubHeader>
          <Label>
            We surveyed over 1,000 Enki learners who closely followed their
            program for 4 weeks. Here’s what they told us:
          </Label>
          <Column>
            <StatLabel>
              <StatHighlightSpan>81% </StatHighlightSpan>are on track to achieve
              their goal
            </StatLabel>
            <StatLabel>
              <StatHighlightSpan>74% </StatHighlightSpan>are already applying
              their new skills
            </StatLabel>
            <StatLabel>
              <StatHighlightSpan>92% </StatHighlightSpan>found Enki’s AI mentor
              essential for progress
            </StatLabel>
          </Column>
          <CTAButton label={'Continue'} onClick={onNext} />
        </Section>
      </Column>
    </PageWrapper>
  );
}
export default function Benefits() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [state, send] = useActor(onboardingService.service);

  Analytics.useTrackViewOnMount(
    Analytics.EVENTS.PROSUMER.ONBOARDING.VIEW_UPSELL_BENEFITS,
    state.context
  );

  return (
    <BenefitsContent
      onNext={() => send(EVENTS.CONTINUE_BENEFITS)}
      shouldShowTrial={!state.context.partnerCode}
      quote={getPersonalizedQuote({ topic: state.context.topic })}
    />
  );
}

function getPersonalizedQuote({ topic }: { topic: string | null }) {
  if (topic === TOPICS.PYTHON) {
    return 'Enki’s program is by far the most effective way to learn how to code.';
  }

  if (topic) {
    return `Enki’s program is by far the most effective way to learn ${topic}.`;
  }

  return 'Enki’s program is by far the most effective way to learn.';
}

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  width: 100%;
`;

const GridItem = styled.div`
  text-align: left;
`;

const GridTitle = styled(P)`
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textActive};
`;

const GridDescription = styled(PSmall)`
  padding: 0;
  margin: 0;
  font-weight: 300;
`;

const GridImage = styled.img`
  height: 120px;
  width: 120px;
  padding: 0;
  margin: 0;
`;

function HeroQuote({
  quote = 'Enki’s program is by far the most effective way to learn.',
}: {
  quote?: string;
}) {
  return (
    <LargeQuote
      quote={quote}
      author="Jeff Feng"
      position="AI & Data Lead @ Google, Airbnb"
      avatarURL="https://img.enkipro.com/017dbbd66a01366c7ad6ada5ddddd322.png"
    />
  );
}

function BenefitsGrid() {
  return (
    <GridWrapper>
      <GridItem>
        <Row>
          <GridImage src="https://img.enkipro.com/79625676c855fc1e6837f26f2a419c8e.png" />
          <Column>
            <GridTitle>Award-winning interactive exercises</GridTitle>
            <GridDescription>
              Enki’s exercises are interactive and customised to your level.
              Designed by technical leaders at top companies, and approved by
              millions of professional learners.
            </GridDescription>
          </Column>
        </Row>
      </GridItem>
      <GridItem>
        <Row>
          <GridImage src="https://img.enkipro.com/4631b47859e1d2bdd596db09fa83fc7c.png" />
          <Column>
            <GridTitle>Unlimited, personalised coaching</GridTitle>
            <GridDescription>
              Imagine a friendly technical colleague that’s always by your side!
              If you’re stuck or want to clarify anything, Enki’s cutting-edge
              coaching AI is there for you.
            </GridDescription>
          </Column>
        </Row>
      </GridItem>
      <GridItem>
        <Row>
          <GridImage src="https://img.enkipro.com/fe6cb6d85a67bb25a293771ad751b6b8.png" />
          <Column>
            <GridTitle>Practical Outcomes</GridTitle>
            <GridDescription>
              The Enki experience is highly practical and customised. We’ll help
              you take the next step in your career. And you’ll earn a
              certificate to prove your skills.
            </GridDescription>
          </Column>
        </Row>
      </GridItem>
    </GridWrapper>
  );
}

const FrobesQuote = styled.img.attrs({
  src: 'https://img.enkipro.com/e19ea131bf6e8666fa0acafe75caf344.png',
})`
  width: 300px;
  margin: 0;
  padding: 0;
`;

const FreeTrialIllustration = styled.img.attrs({
  src: 'https://img.enkipro.com/fe9e594b77a37d43502584db290dab29.png',
})`
  width: 300px;
  margin: 0;
  padding: 0;
`;

const StatLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.textRegular};
`;

const StatHighlightSpan = styled.span`
  color: ${({ theme }) => theme.colors.textActive};
  font-weight: bold;
`;
