import mixpanel from 'mixpanel-browser';
import { useEffectOnce } from 'react-use';

const { REACT_APP_ENV = 'development', REACT_APP_MIXPANEL_TOKEN = 'dummy' } =
  process.env;

const IS_DEV = REACT_APP_ENV === 'development';

if (!IS_DEV) {
  mixpanel.init(REACT_APP_MIXPANEL_TOKEN, {
    track_pageview: 'url-with-path-and-query-string',
  });
} else {
  console.log(
    `Skipping Analytics Mixpanel init as environment = ${REACT_APP_ENV}`
  );
}

export function setUser({
  id,
  email,
  name,
  isSignUp,
}: {
  id: number;
  email: string;
  name: string;
  isSignUp: boolean;
}) {
  if (!IS_DEV) {
    mixpanel.people.set({ email, name, Email: email, $email: email });
    if (isSignUp) {
      mixpanel.alias(id.toString());
    } else {
      mixpanel.identify(id.toString());
    }
  } else {
    console.log(
      `Skipping Analytics Mixpanel setUser as environment = ${REACT_APP_ENV}`
    );
  }
}

export function trackPageView() {
  trackEvent({ event: '$mp_web_page_view' });
}

export function setUserProperties(properties: Record<string, unknown>) {
  if (!IS_DEV) {
    mixpanel.people.set(properties);
  } else {
    console.log(
      `Skipping Analytics Mixpanel setUserProperties as environment = ${REACT_APP_ENV}`,
      properties
    );
  }
}

export function removeUser() {
  if (!IS_DEV) {
    mixpanel.reset();
  } else {
    console.log(
      `Skipping Analytics Mixpanel removeUser as environment = ${REACT_APP_ENV}`
    );
  }
}

export function trackEvent({
  event,
  properties = {},
}: {
  event: string;
  properties?: Record<string, unknown>;
}) {
  if (!IS_DEV) {
    console.log('trackEvent', event, properties);
    mixpanel.track(event, properties);
  } else {
    console.log(
      `Skipping Analytics Mixpanel trackEvent for ${event} as environment = ${REACT_APP_ENV}`,
      properties
    );
  }
}

export const useTrackViewOnMount = (
  event: string,
  properties: Record<string, unknown> = {}
) => {
  // Parse utm params
  const currentUrl = new URL(window.location.href);

  const utm_keys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  const newProps = utm_keys
    .map((key) => {
      const value = currentUrl.searchParams.get(key);
      return { key, value };
    })
    .filter((prop) => prop.value);

  const fullProperties = {
    ...properties,
    ...Object.fromEntries(newProps.map((prop) => [prop.key, prop.value])),
  };

  useEffectOnce(() => {
    trackEvent({
      event,
      properties: fullProperties || {},
    });
  });
};

export const EVENTS = {
  ACTIVITY: {
    QUESTION: {
      TYPE_ANSWER: 'activity_question_type_answer',
    },
  },
  COMMON: {
    VIEW_HOME: 'view_home',
    AUTHENTHICATE: 'authenticate',
  },
  PROSUMER: {
    // tracking this whenever a successfull profile request is made. will use it to get the unique prosumer count
    // we use the profile on every prosumer-related component
    PING: 'prosumer_ping',
    ONBOARDING: {
      VIEW_ASK_LEARNING_GOAL: 'prosumer_onboarding_view_ask_learning_goal',
      VIEW_ASK_TOPIC: 'prosumer_onboarding_view_ask_topic',
      VIEW_ASK_PROFILE: 'prosumer_onboarding_view_ask_profile',
      VIEW_POST_ASK_PROFILE: 'prosumer_onboarding_view_post_ask_profile',
      VIEW_ASK_GOAL: 'prosumer_onboarding_view_ask_goal',
      VIEW_POST_ASK_GOAL: 'prosumer_onboarding_view_post_ask_goal',
      VIEW_ASK_MODULE_KNOWLEDGE:
        'prosumer_onboarding_view_ask_module_knowledge',
      VIEW_ASK_TIME_EFFORT: 'prosumer_onboarding_view_ask_time_effort',
      VIEW_PERRSONALIZATION_LOADER:
        'prosumer_onboarding_view_personalization_loader',
      VIEW_ASK_EMAIL: 'prosumer_onboarding_view_ask_email',
      VIEW_RESULTS: 'prosumer_onboarding_view_results',
      VIEW_UPSELL_BENEFITS: 'prosumer_onboarding_view_upsell_benefits',
      VIEW_UPSELL_PAYMENT: 'prosumer_onboarding_view_payment',
      VIEW_UPSELL_PAYMENT_CALLBACK: 'prosumer_onboarding_view_payment_callback',
      VIEW_PARTNER_WELCOME: 'prosumer_onboarding_view_partner_welcome',
      VIEW_SIGNUP: 'prosumer_onboarding_view_signup',
      VIEW_SIGNUP_CONFIRM_EMAIL:
        'prosumer_onboarding_view_signup_confirm_email',
      VIEW_SIGNUP_CALLBACK: 'prosumer_onboarding_view_signup_callback',
      VIEW_ERROR: 'prosumer_onboarding_view_error',
    },
    UPSELL: {
      VIEW_BENEFITS: 'prosumer_upsell_view_benefits',
      VIEW_PAYMENT: 'prosumer_upsell_view_payment',
      VIEW_PAYMENT_CALLBACK: 'prosumer_upsell_view_payment_callback',
    },
    ACTIVITY: {
      VIEW: 'prosumer_view_activity',
    },
    DASHBOARD: {
      VIEW: 'prosumer_view_dashboard',
    },
  },
  AI: {
    MESSAGE: {
      SEND: 'ai_message_send',
      SEND_LOCKED: 'ai_message_send_locked',
    },
  },
};
