export default function isSubmissionDifferent(
  submission,
  latestSavedSubmission,
  questions
) {
  return (
    submission.difficultyRating !== latestSavedSubmission?.difficulty_rating ||
    submission.feedback !== latestSavedSubmission?.feedback ||
    !!questions.find((question, qIndex) => {
      const currentAnswer =
        question.type === 'PLAYGROUND'
          ? submission.questionSlugToAnswer[question.slug]?.query ?? ''
          : submission.questionSlugToAnswer[question.slug]?.value ?? '';
      const submittedAnswer = latestSavedSubmission?.solutions[qIndex] ?? '';

      return currentAnswer !== submittedAnswer;
    })
  );
}
