import React from 'react';
import SoloOnboardingContext from '../context';
import {
  CenteredContainer,
  CTAButton,
  Header,
  Label,
  MainImage,
  ReadableContainer,
} from './common';
import { useActor } from '@xstate/react';
import { EVENTS } from '../state-machine';

export default function AppUser() {
  const onboardingService = React.useContext(SoloOnboardingContext);
  const [, send] = useActor(onboardingService.service);
  return <AppUserPage onContinue={() => send(EVENTS.CONTINUE_APP_USER)} />;
}

export function AppUserPage({ onContinue }: { onContinue: () => void }) {
  return (
    <ReadableContainer>
      <CenteredContainer>
        <Header>Hi there!</Header>
        <Label>
          We noticed you've used Enki's mobile app before - so you've qualified
          for <b>15% off your Enki Desktop membership!</b> 🎉
        </Label>

        <MainImage
          src={'https://img.enkipro.com/8147d20b8a6205c3165ec8a38ed8066c.png'}
        />

        <Label>
          Note:{' '}
          <b>
            Enki Desktop uses a separate account and payment plan to the mobile
            app.
          </b>
          <br />
          We've re-imagined learning from the ground up, with desktop-specific
          content, features and AI coaching. Enjoy!
        </Label>

        <CTAButton label="Continue" onClick={onContinue} />
      </CenteredContainer>
    </ReadableContainer>
  );
}
