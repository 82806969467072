import useAuth from './use-auth';
import {
  getTeamsActivity,
  getPreviewActivity,
  getTeamsDashboard,
  getPreviewDashboard,
  getProsumerDashboard,
  getHome,
  getProjectExamples,
  getProjectInfo,
  getRetroSurvey,
  getProsumerProfile,
  getProsumerActivity,
  getStreakStats,
} from '../hydra';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { EVENTS, trackEvent } from '../analytics';

const queryOptions = {
  retry: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 1000 * 60 * 1, // 1 minute
};

// rewrite to be similar to useHome, using react-query, and the isAuthenthicated check
export function useHome() {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-home', isAuthenticated],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const home = await getHome(token);

      return home;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useStreakStats() {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-streak-stats', isAuthenticated],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const stats = await getStreakStats(token);
      return stats;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useTeamsActivity(
  teamSlug: string,
  missionSlug: string,
  activitySlug: string
) {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    [
      'get-teams-activity',
      isAuthenticated,
      teamSlug,
      missionSlug,
      activitySlug,
    ],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const mission = await getTeamsActivity(token, {
        teamSlug,
        missionSlug,
        activitySlug,
      });
      return mission;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function usePreviewActivity(missionSlug: string, activitySlug: string) {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const query = useLocation();

  let solutions: string[] = [];
  try {
    const parsed = queryString.parse(query.search);
    if (typeof parsed === 'object' && Array.isArray(parsed?.solutions)) {
      solutions = parsed.solutions;
    }
  } catch (err) {
    console.error('cannot parse solutions query');
  }

  const state = useQuery(
    ['get-preview-activity', isAuthenticated, missionSlug, activitySlug],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const mission = await getPreviewActivity(token, {
        missionSlug,
        activitySlug,
      });

      return {
        ...mission,
        latest_submission: {
          id: 999,
          updated_at: new Date().toString(),
          created_at: new Date().toString(),
          difficulty_rating: null,
          feedback: null,
          seconds_spent: null,
          solutions,
          activity_session_id: 999,
          ai_review: null,
          answer_states: null,
        },
      };
    },
    queryOptions
  );

  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useProsumerActivity(missionSlug: string, activitySlug: string) {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-prosumer-activity', isAuthenticated, missionSlug, activitySlug],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const mission = await getProsumerActivity(token, {
        missionSlug,
        activitySlug,
      });
      return mission;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useTeamsDashboard(trackSlug: string) {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-track-stats', isAuthenticated, trackSlug],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const stats = await getTeamsDashboard(token, { trackSlug });
      return stats;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function usePreviewDashboard(contentSlug: string) {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-preview-stats', isAuthenticated, contentSlug],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const stats = await getPreviewDashboard(token, { contentSlug });
      return stats;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useProsumerDashboard(contentSlug: string) {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-prosumer-stats', isAuthenticated, contentSlug],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const stats = await getProsumerDashboard(token, { contentSlug });
      return stats;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useProjectExamples() {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-project-examples', isAuthenticated],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const examples = await getProjectExamples(token);
      return examples;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useProjectInfo(trackSlug: string) {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-project-info', isAuthenticated, trackSlug],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const projectInfo = await getProjectInfo(token, { trackSlug });
      return projectInfo;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useGetRetroSurvey(teamSlug: string) {
  const { getTokenSilently, isAuthenticated } = useAuth();
  const state = useQuery(
    ['get-retro-survey', isAuthenticated, teamSlug],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const survey = await getRetroSurvey(token, { teamSlug });
      return survey;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}

export function useGetProsumerProfile() {
  const { getTokenSilently, isAuthenticated } = useAuth();

  const state = useQuery(
    ['prosumer-profile', isAuthenticated],
    async () => {
      if (!isAuthenticated) {
        return null;
      }
      const token = await getTokenSilently();
      const profile = await getProsumerProfile(token);

      // TODO: why do we need this, doesn't react query capture the error properly?
      if (!profile?.hasProfile) {
        return null;
      }

      trackEvent({
        event: EVENTS.PROSUMER.PING,
      });
      return profile;
    },
    queryOptions
  );
  return {
    value: state.data,
    loading: state.isLoading,
    error: state.error,
    retry: state.refetch,
  };
}
