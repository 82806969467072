import React from 'react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';

import styled from 'styled-components';

import 'xterm/css/xterm.css';
import debounce from 'lodash.debounce';

const TerminalWrapper = styled.div`
  .xterm-viewport {
    background-color: transparent !important;
  }
  .xterm-cursor,
  .xterm-cursor-outline {
    visibility: ${({ isAwaitingInput }) =>
      isAwaitingInput ? 'visible' : 'hidden'} !important;
  }

  height: 100%;
  display: flex;
  background-color: transparent;
  padding: 10px 15px;
  border-radius: 8px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const KEYS = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ENTER: 'Enter',
  BACKSPACE: 'Backspace',
};

const XTerm = React.forwardRef(function XTerm(
  { isAwaitingInput = false, onInput },
  ref
) {
  const terminalViewRef = React.useRef(null);
  const terminalRef = React.useRef(null);
  const fitAddonRef = React.useRef(null);
  const userInput = React.useRef('');
  const shouldCaptureInput = React.useRef(false);
  const inputStartCoordinates = React.useRef({
    cursorX: null,
    cursorY: null,
  });

  React.useImperativeHandle(ref, () => ({
    write: (data) => {
      terminalRef.current.write(data.replace(/\n/g, '\r\n'));
    },
    reset: () => {
      terminalRef.current.reset();
    },
  }));

  React.useEffect(() => {
    if (isAwaitingInput && terminalRef.current) {
      terminalRef.current.focus();
      userInput.current = '';
      shouldCaptureInput.current = true;
      inputStartCoordinates.current = {
        cursorX: terminalRef.current.buffer.active.cursorX,
        cursorY: terminalRef.current.buffer.active.cursorY,
      };
    } else {
      shouldCaptureInput.current = false;
    }
  }, [isAwaitingInput]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = React.useCallback(
    debounce(() => {
      if (fitAddonRef.current) {
        try {
          fitAddonRef.current.fit();
        } catch (e) {
          console.error(e);
        }
      }
    }, 200),
    []
  ); // we don't want this reference to change

  const isCursorAtInputStart = React.useCallback(() => {
    const cursorX = terminalRef.current.buffer.active.cursorX;
    const cursorY = terminalRef.current.buffer.active.cursorY;

    return (
      cursorX === inputStartCoordinates.current.cursorX &&
      cursorY === inputStartCoordinates.current.cursorY
    );
  }, []);

  const isCursorAtStartOfNewLine = React.useCallback(() => {
    const cursorX = terminalRef.current.buffer.active.cursorX;
    const cursorY = terminalRef.current.buffer.active.cursorY;

    return cursorX === 0 && cursorY !== inputStartCoordinates.current.cursorY;
  }, []);

  const isCursorAtEndOfLine = React.useCallback(() => {
    const cursorX = terminalRef.current.buffer.active.cursorX;

    return cursorX === terminalRef.current.cols;
  }, []);

  const addNewLine = React.useCallback(() => {
    terminalRef.current.write('\r\n');
  }, []);

  const deleteAtStartOfNewLine = React.useCallback(() => {
    terminalRef.current.write('\x1b[1A');
    terminalRef.current.write('\x1b[' + terminalRef.current.cols + 'C');
    terminalRef.current.write('\x1b[1P');
  }, []);

  const deleteAtEndOfLine = React.useCallback(() => {
    terminalRef.current.write('\x1b[1P');
  }, []);

  const deleteAtCursor = React.useCallback(() => {
    terminalRef.current.write('\b \b');
  }, []);

  const onKey = React.useCallback(
    (event) => {
      if (!shouldCaptureInput.current) {
        return;
      }
      const key = event.domEvent.key;

      if (
        [
          KEYS.ARROW_UP,
          KEYS.ARROW_DOWN,
          KEYS.ARROW_LEFT,
          KEYS.ARROW_RIGHT,
        ].includes(key)
      ) {
        return;
      }

      if (key === KEYS.ENTER) {
        addNewLine();
        onInput(userInput.current);
        return;
      }

      if (key === KEYS.BACKSPACE) {
        if (isCursorAtInputStart()) {
          return;
        }

        if (isCursorAtStartOfNewLine()) {
          deleteAtStartOfNewLine();
          return;
        }

        if (isCursorAtEndOfLine()) {
          deleteAtEndOfLine();
          return;
        }

        if (userInput.current.length) {
          deleteAtCursor();
          userInput.current = userInput.current.slice(0, -1);
        }

        return;
      }

      userInput.current += event.key;
      terminalRef.current.write(event.key);
    },
    [
      onInput,
      addNewLine,
      isCursorAtInputStart,
      isCursorAtEndOfLine,
      isCursorAtStartOfNewLine,
      deleteAtStartOfNewLine,
      deleteAtEndOfLine,
      deleteAtCursor,
    ]
  );

  React.useEffect(() => {
    const terminal = new Terminal({
      fontSize: 15,
      fontFamily: 'Roboto Mono',
      cursorBlink: true,
    });
    const fitAddon = new FitAddon();

    terminalRef.current = terminal;
    fitAddonRef.current = fitAddon;

    terminal.open(terminalViewRef.current);
    terminal.loadAddon(fitAddon);
    terminal.onKey(onKey);

    fitAddon.fit();

    window.addEventListener('resize', handleResize);

    const selectionListener = terminalRef.current.onSelectionChange(() => {
      document.dispatchEvent(
        new CustomEvent('terminalselectionchange', {
          detail: {
            selection: terminalRef.current.getSelection(),
            section: getTerminalTextFromDOM(terminalRef.current),
          },
        })
      );
    });

    return () => {
      selectionListener.dispose();
      terminal.dispose();
      fitAddonRef.current = null;
      terminalRef.current = null;

      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize, onKey]);

  function getTerminalTextFromDOM(terminal) {
    return Array.from(terminal.element.querySelectorAll('.xterm-rows>div'))
      .map((e) =>
        Array.from(e.querySelectorAll('span'))
          .map((e) => e.textContent)
          .join('')
      )
      .filter((e) => e != '')
      .join('\n');
  }

  return (
    <TerminalWrapper ref={terminalViewRef} isAwaitingInput={isAwaitingInput} />
  );
});

export default XTerm;
