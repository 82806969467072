import { H1, H4, PExtraSmall, PSmall } from '../../elements/Text';
import { QuestionProps } from '.';
import React from 'react';
import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import {
  BottomSpacer,
  ButtonsRow,
  FullScreenQuestion,
  ReadableContainer,
  ReadableMarkdown,
  getQuestionText,
} from './common';
import Button from '../../elements/Button';
import styled, { useTheme } from 'styled-components';
import Modal from '../../Modal/Modal';
import { CloseIcon } from '../../Icons';
import TableauEmbed from '../../Embeds/TableauEmbed';
import { Input } from '../../elements/Input';
import MentorReview from '../MentorComment';

const TABLEAU_EMBED_REGEX = /https:\/\/public.tableau.com\/.*/;
const TABLEAU_NO_EMBED_REGEX =
  /https:\/\/public\.tableau\.com\/profile\/.*\/vizhome\/(.*)\/(.*)/;

export default function LookerExternalQuestion({
  question,
  answer,
}: QuestionProps) {
  const theme = useTheme();
  const [activity, dispatch] = React.useContext(ActivityContext);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const link = question?.tableau_link ?? '';

  const [isTableauURLValid, setIsTableauURLValid] = React.useState(true);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!answer.value) {
      setIsTableauURLValid(true);
    } else {
      setIsTableauURLValid(
        !!answer.value.match(TABLEAU_EMBED_REGEX) ||
          !!answer.value.match(TABLEAU_NO_EMBED_REGEX)
      );
    }
  }, [answer.value]);

  const onTableauExternalQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_TABLEAU_EXTERNAL_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <FullScreenQuestion>
      {isPreviewModalOpen && (
        <TableauPreviewModal
          url={answer.value}
          onCloseModal={() => setIsPreviewModalOpen(false)}
        />
      )}
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      {/* @ts-expect-error - Markdown component is not typed properly */}
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <PExtraSmall>
          Open the Tableau Workbook, modify it as required, publish the new
          version, and paste the URL of the published workbook into the box
          below.
        </PExtraSmall>
        <ButtonsRow>
          <Button
            type="secondary"
            label="Open Tableau Workbook"
            faIcon="fas fa-chart-pie"
            onClick={() => window.open(link, '_blank')}
          />
          <Button
            type="default"
            label="Preview Answer"
            isDisabled={!answer?.value || !isTableauURLValid}
            faIcon="fas fa-search"
            onClick={() => setIsPreviewModalOpen(true)}
            color={
              !answer?.value || !isTableauURLValid
                ? theme.colors.textRegular
                : theme.colors.buttonPrimary
            }
          />
        </ButtonsRow>
        <Input
          type="link"
          placeholder={'Enter your answer here'}
          value={answer.value}
          onChange={(e) => onTableauExternalQuestionTextChange(e.target.value)}
        />
      </ReadableContainer>
      <BottomSpacer />
    </FullScreenQuestion>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;

  > * {
    margin: 0;
  }
`;

function TableauPreviewModal({
  url,
  onCloseModal,
}: {
  url: string;
  onCloseModal: () => void;
}) {
  const theme = useTheme();
  return (
    <Modal
      style={{ width: '100%', maxWidth: theme.dimensions.readableColumnWidth }}
    >
      <Row>
        <H4>Tableau Answer Preview</H4>
        <CloseIcon
          style={{ padding: 0, alignSelf: 'flex-start' }}
          onClick={onCloseModal}
        />
      </Row>
      <PSmall>
        Here is a preview of your answer. Make sure that this reflects your
        intended submission.
      </PSmall>
      <TableauEmbed isClickable={false} url={url} />

      <div style={{ height: '52px' }} />
    </Modal>
  );
}
