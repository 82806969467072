import React from 'react';
import styled from 'styled-components';
import { useHome, useGetProsumerProfile } from '../hooks/use-hydra';
import Button from '../components/elements/Button';
import { H1, H2, P } from '../components/elements/Text';
import PageWrapper from '../components/PageWrapper';
import PageWithAsyncData from '../components/PageWithAsyncData';
import Navbar from '../components/Navbar';
import { Helmet } from 'react-helmet-async';
import { getHomePageTitle } from '../routing';
import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as Analytics from '../analytics';
import OnboardingTrackCard from '../components/Home/OnboardingTrackCard';
import {
  OnboardedTrackCard,
  ActiveTrackCard,
} from '../components/Home/TrackCard';
import ProsumerContentModuleCard from '../components/Home/ProsumerContentModuleCard';
import OnboardingModal from '../components/Onboarding';
import {
  ActiveTeamsTrackWithProgress,
  HomePayload,
  OnboardingTrack,
  ProsumerContentModule,
  ProsumerProfilePayload,
} from '../hydra';
import CTACard from '../components/Home/CTACard';
import StreakCard from '../components/Home/StreakCard';

day.extend(utc);

const ScrollablePageWrapper = styled.div`
  height: calc(100vh - 56px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;

const PageContentWrapper = styled(PageWrapper)`
  max-width: 1100px;
  margin: 0 auto 82px;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  gap: 32px;
  margin: 32px 0px;
`;

function Home() {
  const campQuery = useHome();
  const prosumerQuery = useGetProsumerProfile();

  Analytics.useTrackViewOnMount(Analytics.EVENTS.COMMON.VIEW_HOME);

  // we do "!campQuery.value" to check isloading such that we don't render the loader while re-fetching the data
  // this action makes the open onboarding modal to close when moving through the onboarding steps
  const isPageLoading =
    prosumerQuery.loading || (campQuery.loading && !campQuery.value);

  return (
    <PageWithAsyncData
      isLoading={isPageLoading}
      error={campQuery.error || prosumerQuery.error}
      retry={() => {
        campQuery.retry();
        prosumerQuery.retry();
      }}
      className={''}
    >
      <Helmet>
        <title>{getHomePageTitle()}</title>
      </Helmet>
      <Navbar
        breadCrumbs={[
          {
            label: 'Home',
            url: null,
          },
        ]}
      />
      <Contents />
    </PageWithAsyncData>
  );
}

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  gap: 32px;
  margin-top: 36px;

  @media screen and (max-width: 664px) {
    display: flex;
    flex-direction: column;
  }
`;

const Contents = () => {
  const [openTeamsOnboarding, setOpenTeamsOnboarding] =
    React.useState<null | { task: string; track: OnboardingTrack }>(null);
  const { value: prosumerProfile, retry: refreshProsumerData } =
    useGetProsumerProfile();
  const { value: teamsProfile, retry: refreshTeamsData } = useHome();

  const teamTracks = teamsProfile?.tracks ?? [];
  const onboardingTracks = teamsProfile?.onboardingTracks ?? [];
  const onboardedUnstartedTracks = onboardingTracks.filter(
    (oc) =>
      oc.onboarding.survey.every(({ isCompleted }) => isCompleted) &&
      oc.onboarding.slack.isCompleted
  );
  const tracksToOnboard = onboardingTracks.filter(
    (oc) =>
      oc.onboarding.survey.some(({ isCompleted }) => !isCompleted) ||
      !oc.onboarding.slack.isCompleted
  );

  const prosumerContentModules = prosumerProfile?.contentModules ?? [];

  return (
    <ScrollablePageWrapper>
      <PageContentWrapper>
        <H1>Welcome!</H1>
        <Progress
          prosumerProfile={prosumerProfile}
          teamsProfile={teamsProfile}
          setOpenTeamsOnboarding={setOpenTeamsOnboarding}
        />
        <H2>All modules</H2>
        <Modules
          openTeamsOnboarding={openTeamsOnboarding}
          setOpenTeamsOnboarding={setOpenTeamsOnboarding}
          tracksToOnboard={tracksToOnboard}
          teamTracks={teamTracks}
          onboardedUnstartedTracks={onboardedUnstartedTracks}
          prosumerContentModules={prosumerContentModules}
          refreshTeamsData={refreshTeamsData}
          refreshProsumerData={refreshProsumerData}
        />
        <Help />
      </PageContentWrapper>
    </ScrollablePageWrapper>
  );
};

function Progress({
  prosumerProfile,
  teamsProfile,
  setOpenTeamsOnboarding,
}: {
  prosumerProfile: ProsumerProfilePayload | null | undefined;
  teamsProfile: HomePayload | null | undefined;
  setOpenTeamsOnboarding: (
    value: {
      task: string;
      track: OnboardingTrack;
    } | null
  ) => void;
}) {
  return (
    <Row>
      <CTACard
        prosumerProfile={prosumerProfile}
        teamsProfile={teamsProfile}
        setOpenTeamsOnboarding={setOpenTeamsOnboarding}
      />
      <StreakCard />
    </Row>
  );
}

function Modules({
  openTeamsOnboarding,
  setOpenTeamsOnboarding,
  tracksToOnboard,
  teamTracks,
  onboardedUnstartedTracks,
  prosumerContentModules,
  refreshTeamsData,
  refreshProsumerData,
}: {
  openTeamsOnboarding: { task: string; track: OnboardingTrack } | null;
  setOpenTeamsOnboarding: (
    value: {
      task: string;
      track: OnboardingTrack;
    } | null
  ) => void;
  tracksToOnboard: OnboardingTrack[];
  teamTracks: ActiveTeamsTrackWithProgress[];
  onboardedUnstartedTracks: OnboardingTrack[];
  prosumerContentModules: ProsumerContentModule[];
  refreshTeamsData: () => void;
  refreshProsumerData: () => void;
}) {
  return (
    <CardsContainer>
      {openTeamsOnboarding && (
        <OnboardingModal
          onRefreshData={refreshTeamsData}
          onboardingTask={openTeamsOnboarding}
          onClose={() => setOpenTeamsOnboarding(null)}
        />
      )}
      {tracksToOnboard.map((track) => (
        <OnboardingTrackCard
          key={`onboarding-track-card-${track.slug}`}
          track={track}
          setOnboardingTrack={() =>
            setOpenTeamsOnboarding({
              task: 'track-onboarding', // this is redundant atm, as the community onboarding has been deprecated
              track,
            })
          }
        />
      ))}
      {teamTracks.map((track) => (
        <ActiveTrackCard key={`track-card-${track.slug}`} track={track} />
      ))}
      {onboardedUnstartedTracks.map((track) => (
        <OnboardedTrackCard key={`track-card--${track.slug}`} track={track} />
      ))}
      {prosumerContentModules.map((module, ind) => (
        <ProsumerContentModuleCard
          key={`prosumer-content-module-${module.content_id}-${ind}`}
          module={module}
          refreshProfile={refreshProsumerData}
        />
      ))}
    </CardsContainer>
  );
}

function Help() {
  return (
    <>
      <hr style={{ width: '100%' }} />
      <H2>Have any questions?</H2>
      <P>E-mail us via the button below.</P>
      <div style={{ width: 'fit-content' }}>
        <Button
          label="Get in touch"
          type="primary"
          onClick={() => window.open('mailto:concierge@enki.com')}
        />
      </div>
    </>
  );
}

export default Home;
