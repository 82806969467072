import React from 'react';
import styled from 'styled-components';
import Lightbox from 'react-spring-lightbox';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: ${({ theme }) => theme.dimensions.spacing.default.top};
  padding-bottom: ${({ theme }) => theme.dimensions.spacing.default.bottom};
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 8px;
  margin-top: 0;
  padding-bottom: 0;

  :hover {
    cursor: zoom-in;
  }
`;

const StyledLightbox = styled(Lightbox)`
  background-color: rgba(0, 0, 0, 0.5);

  .lightbox-image {
    :hover {
      cursor: zoom-in;
    }
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  background-color: ${({ theme }) => theme.colors.bgPage};
  padding: 1em;
`;

const HeaderText = styled.p`
  color: ${({ theme }) => theme.colors.text};
  margin: 0;

  :hover {
    cursor: pointer;
  }
`;

const Header = ({ onClose }) => (
  <HeaderWrapper>
    <HeaderText onClick={onClose}>
      Close <i className="fas fa-times" />
    </HeaderText>
  </HeaderWrapper>
);

const LightboxImageRenderer = ({ alt, src, ...props }) => {
  // TODO: we could render the image markdown title as a caption, but first we need to update all content as some of the names are broken/messy (eg. Screenshot01023123)

  const [isOpen, setIsOpen] = React.useState(false);
  const onOpenImage = React.useCallback(() => {
    setIsOpen(true);
  }, []);
  const onCloseImage = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const images = React.useMemo(
    () => [
      {
        src,
        loading: 'lazy',
      },
    ],
    [src]
  );

  return (
    <Wrapper>
      <Image alt={alt} src={src} {...props} onClick={onOpenImage} />
      {isOpen && (
        <StyledLightbox
          isOpen={isOpen}
          onPrev={() => {}}
          onNext={() => {}}
          images={images}
          currentIndex={0}
          onClose={onCloseImage}
          singleClickToZoom={true}
          renderHeader={() => <Header onClose={onCloseImage} />}
        />
      )}
    </Wrapper>
  );
};

export default LightboxImageRenderer;
