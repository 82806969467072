import { H1, H4, PExtraSmall, PSmall } from '../../elements/Text';
import { QuestionProps } from '.';
import React from 'react';
import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import {
  BottomSpacer,
  ButtonsRow,
  FullScreenQuestion,
  getQuestionText,
} from './common';
import { ReadableContainer, ReadableMarkdown } from '../Containers';

import Button from '../../elements/Button';
import GoogleSheetsEmbed, {
  getGoogleSheetsCopyUrl,
} from '../../Embeds/GoogleSheetsEmbed';
import styled, { useTheme } from 'styled-components';
import Modal from '../../Modal/Modal';
import { CloseIcon } from '../../Icons';
import { Input } from '../../elements/Input';
import MentorReview from '../MentorComment';

const SHEETS_EMBED_REGEX = /https:\/\/docs.google.com\/spreadsheets\/d.*/;

export default function SheetsExternalQuestion({
  question,
  answer,
}: QuestionProps) {
  const theme = useTheme();
  const [activity, dispatch] = React.useContext(ActivityContext);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const onGoogleSheetsExternalQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_GOOGLE_SHEETS_EXTERNAL_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const [isGoogleSheetsURLValid, setIsGoogleSheetsURLValid] =
    React.useState(true);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = React.useState(false);

  const link = question?.google_sheets_link ?? '';
  React.useEffect(() => {
    if (!answer.value) {
      setIsGoogleSheetsURLValid(true);
    } else if (!answer.value.match(SHEETS_EMBED_REGEX)) {
      setIsGoogleSheetsURLValid(false);
    } else {
      setIsGoogleSheetsURLValid(true);
    }
  }, [answer.value]);

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <FullScreenQuestion>
      {isPreviewModalOpen && (
        <GoogleSheetsPreviewModal
          url={answer.value}
          onCloseModal={() => setIsPreviewModalOpen(false)}
        />
      )}
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      {/* @ts-expect-error - Markdown component is not typed properly */}
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <PExtraSmall>
          Open the Sheet, copy it, carry out your work, share it publicly, and
          copy its URL in the box below. Once you're done, you'll be able to
          preview and verify your answer.
        </PExtraSmall>
        <ButtonsRow>
          <Button
            type="secondary"
            label="Open Sheet"
            faIcon="fas fa-chart-pie"
            onClick={() => window.open(getGoogleSheetsCopyUrl(link), '_blank')}
          />
          <Button
            type="secondary"
            label="Preview Answer"
            isDisabled={!answer?.value || !isGoogleSheetsURLValid}
            faIcon="fas fa-search"
            onClick={() => setIsPreviewModalOpen(true)}
            color={
              !answer?.value || !isGoogleSheetsURLValid
                ? theme.colors.textRegular
                : theme.colors.buttonPrimary
            }
          />
        </ButtonsRow>
        <Input
          type="link"
          placeholder={'Enter your answer here'}
          value={answer.value}
          onChange={(e) =>
            onGoogleSheetsExternalQuestionTextChange(e.target.value)
          }
        />
        <BottomSpacer />
      </ReadableContainer>
    </FullScreenQuestion>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;

  > * {
    margin: 0;
  }
`;

function GoogleSheetsPreviewModal({
  url,
  onCloseModal,
}: {
  url: string;
  onCloseModal: () => void;
}) {
  const theme = useTheme();
  return (
    <Modal
      style={{ width: '100%', maxWidth: theme.dimensions.readableColumnWidth }}
    >
      <Row>
        <H4>Google Sheets Answer Preview</H4>
        <CloseIcon
          style={{ padding: 0, alignSelf: 'flex-start' }}
          onClick={onCloseModal}
        />
      </Row>
      <PSmall>
        Here is a preview of your answer. Make sure that this reflects your
        intended submission.
      </PSmall>
      <GoogleSheetsEmbed
        isClickable={false}
        isCopyable={false}
        url={url}
        id="looker-answer-preview"
      />
      <div style={{ height: '32px' }} />
    </Modal>
  );
}
