import styled, { css } from 'styled-components';
import theme from '../../theme';
import { addOpacityToHex, mixColors } from '../../utils';
import { Link } from 'react-router-dom';
import { Tooltip as UnstyledTooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export const Tooltip = styled(UnstyledTooltip)`
  font-style: normal;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 16px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  border: 1px solid ${({ theme }) => theme.colors.divider} !important;
  border-radius: 4px !important;

  background-color: ${({ theme }) => theme.colors.bgPage} !important;
  backdrop-filter: saturate(150%) blur(150px) !important;
  z-index: 3 !important;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ButtonWrapper = styled(({ href, ...rest }) =>
  href ? <Link to={href} {...rest} /> : <button {...rest} />
)`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;

  padding: 15px 19px;
  gap: 4px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  outline: none;
  transition: all 300ms ease-in-out;

  background-clip: padding-box;

  margin-top: 16px;
  white-space: nowrap;

  ${({ styles }) => styles && styles.default}

  &:not([disabled]):hover {
    ${({ styles }) => styles && styles.hover}
  }

  &:not([disabled]):active {
    ${({ styles }) => styles && styles.active}
    transition: filter 0s;
    transition: background-color 0s;
    transition: border-color 0s;
  }

  &:disabled {
    ${({ styles }) => styles && styles.disabled}
  }

  gap: 8px;

  ${({ isLoading }) => isLoading && `pointer-events:none;`}
`;

const ButtonLabel = styled.span`
  text-align: center;
  margin-top: 0;
  padding-bottom: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

const FontAwesomeIcon = styled.i`
  color: ${({ color }) => color};
  transition: all 300ms ease-in-out;
`;

const ImageIcon = styled.img`
  width: 22px;
  height: 22px;
  margin: 0;
  padding: 0;
  transition: all 300ms ease-in-out;
`;

const Icon = ({
  faIcon = '',
  iconUrl = '',
  color,
}: {
  faIcon: string;
  iconUrl: string;
  color?: string;
}) =>
  faIcon ? (
    <FontAwesomeIcon className={faIcon} color={color} />
  ) : iconUrl ? (
    <ImageIcon src={iconUrl} />
  ) : null;

const Button = ({
  id = uuidv4(),
  label,
  color = theme.colors.buttonPrimary,
  backgroundColor = null,
  type = 'default', // can also be "primary" and "secondary"
  isDisabled = false,
  isLoading = false,
  onClick = () => {},
  faIcon = '',
  iconUrl = '',
  className = '',
  title = '',
  showIconBeforeLabel = false,
  style = {},
  href = null,
  tooltip = '',
  children = null,
}: {
  id?: string;
  label: string;
  color?: string;
  backgroundColor?: string | null;
  type?: 'default' | 'primary' | 'secondary';
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  faIcon?: string;
  iconUrl?: string;
  className?: string;
  title?: string;
  showIconBeforeLabel?: boolean;
  style?: React.CSSProperties;
  href?: string | null;
  tooltip?: string;
  children?: React.ReactNode;
}) => {
  const { styles, colors } = getButtonStyles({
    buttonColor: color,
    backgroundColor,
    type,
  });

  // TODO: this bugs for some colors
  const labelColor = isDisabled
    ? colors.disabledBackgroundColor
    : colors.defaultLabelColor;

  return (
    <>
      <ButtonWrapper
        // ref={parent}
        href={href}
        title={title}
        className={`${className} button-tooltip-${id}`}
        styles={styles}
        disabled={isDisabled}
        isLoading={isLoading}
        onClick={onClick}
        style={style}
      >
        {showIconBeforeLabel && (
          <Icon
            faIcon={isLoading ? 'fa-solid fa-loader fa-spin' : faIcon}
            iconUrl={iconUrl}
            color={labelColor}
          />
        )}
        {children ? (
          <Column>
            {label && <ButtonLabel color={labelColor}>{label}</ButtonLabel>}
            {children}
          </Column>
        ) : (
          <ButtonLabel color={labelColor}>{label}</ButtonLabel>
        )}

        {!showIconBeforeLabel && (
          <Icon
            faIcon={isLoading ? 'fa-solid fa-loader fa-spin' : faIcon}
            iconUrl={iconUrl}
            color={labelColor}
          />
        )}
      </ButtonWrapper>
      {tooltip && (
        <Tooltip noArrow anchorSelect={`.button-tooltip-${id}`} place="top">
          {tooltip}
        </Tooltip>
      )}
    </>
  );
};

// TODO: get rid of some of the complexity here..
function getButtonStyles({
  type,
  buttonColor,
  backgroundColor,
}: {
  type: string;
  buttonColor: string;
  backgroundColor: string | null;
}) {
  if (type === 'primary') {
    const defaultLabelColor = backgroundColor || theme.colors.bgPage;
    const defaultBackgroundColor = backgroundColor
      ? mixColors(backgroundColor, buttonColor, 0.85)
      : addOpacityToHex(buttonColor, 0.85);
    const hoverBackgroundColor = backgroundColor
      ? mixColors(backgroundColor, buttonColor, 1)
      : addOpacityToHex(buttonColor, 1);
    const activeBackgroundColor = backgroundColor
      ? mixColors(backgroundColor, buttonColor, 0.85)
      : addOpacityToHex(buttonColor, 0.85);
    const disabledBackgroundColor = backgroundColor
      ? mixColors(backgroundColor, buttonColor, 0.25)
      : addOpacityToHex(buttonColor, 0.25);

    return {
      colors: {
        defaultLabelColor,
        defaultBackgroundColor,
        hoverBackgroundColor,
        activeBackgroundColor,
        disabledBackgroundColor,
      },
      styles: {
        default: css`
          color: ${backgroundColor || theme.colors.bgPage};
          background-color: ${defaultBackgroundColor};
          border-color: ${defaultBackgroundColor};
        `,
        hover: css`
          background-color: ${hoverBackgroundColor};
          border-color: ${hoverBackgroundColor};
        `,
        active: css`
          background-color: ${activeBackgroundColor};
          border-color: ${activeBackgroundColor};
          filter: brightness(1.1);
        `,
        disabled: css`
          color: ${backgroundColor || theme.colors.bgPage};
          background-color: ${disabledBackgroundColor};
          border-color: ${disabledBackgroundColor};
        `,
      },
    };
  }

  if (type === 'secondary') {
    const defaultLabelColor = buttonColor;
    const defaultBackgroundColor = backgroundColor
      ? mixColors(backgroundColor, buttonColor, 0.1)
      : addOpacityToHex(buttonColor, 0.1);
    const hoverBackgroundColor = backgroundColor
      ? mixColors(backgroundColor, buttonColor, 0.15)
      : addOpacityToHex(buttonColor, 0.15);
    const activeBackgroundColor = backgroundColor
      ? mixColors(backgroundColor, buttonColor, 0.2)
      : addOpacityToHex(buttonColor, 0.2);
    const disabledBackgroundColor = backgroundColor
      ? mixColors(backgroundColor, buttonColor, 0.05)
      : addOpacityToHex(buttonColor, 0.05);

    return {
      colors: {
        defaultLabelColor,
        defaultBackgroundColor,
        hoverBackgroundColor,
        activeBackgroundColor,
        disabledBackgroundColor,
      },
      styles: {
        default: css`
          color: ${buttonColor};
          background-color: ${defaultBackgroundColor};
          border-color: ${defaultBackgroundColor};
        `,
        hover: css`
          background-color: ${hoverBackgroundColor};
          border-color: ${hoverBackgroundColor};
        `,
        active: css`
          background-color: ${activeBackgroundColor};
          border-color: ${activeBackgroundColor};
        `,
        disabled: css`
          color: ${backgroundColor
            ? mixColors(backgroundColor, buttonColor, 0.2)
            : addOpacityToHex(buttonColor, 0.2)};
          background-color: ${disabledBackgroundColor};
          border-color: ${disabledBackgroundColor};
        `,
      },
    };
  }

  const defaultLabelColor = buttonColor;
  // we actually don't use this for the background, but for the border (as the background is transparent)
  const defaultBackgroundColor = backgroundColor || 'transparent';
  const hoverBackgroundColor = backgroundColor
    ? mixColors(backgroundColor, buttonColor, 0.15)
    : addOpacityToHex(buttonColor, 0.15);
  const activeBackgroundColor = backgroundColor
    ? mixColors(backgroundColor, buttonColor, 0.2)
    : addOpacityToHex(buttonColor, 0.2);
  const disabledBackgroundColor = backgroundColor
    ? mixColors(backgroundColor, buttonColor, 0.1)
    : addOpacityToHex(buttonColor, 0.1);
  return {
    colors: {
      defaultLabelColor,
      defaultBackgroundColor,
    },
    styles: {
      default: css`
        color: ${buttonColor};
        background-color: ${defaultBackgroundColor};
        border-color: ${disabledBackgroundColor};
      `,
      hover: css`
        background-color: ${hoverBackgroundColor};
        border-color: ${hoverBackgroundColor};
        color: ${buttonColor};
      `,
      active: css`
        background-color: ${activeBackgroundColor};
        border-color: ${activeBackgroundColor};
      `,
      disabled: css`
        color: ${disabledBackgroundColor};
        background-color: ${defaultBackgroundColor};
        border-color: ${disabledBackgroundColor};
      `,
    },
  };
}

export default Button;
