import { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import CodeBlock from './CodeBlock';
import EmbeddedLinkRenderer from './EmbeddedLinkRenderer';
import LightboxImageRenderer from './LightboxImageRenderer';
import ParagraphSkipImageAndEmbed from './ParagraphSkipImageAndEmbed';
import remarkGfm from 'remark-gfm';

const Wrapper = styled.div`
  pre {
    border-radius: 8px;
  }
  ${(props) => props.css}
`;

const TableWrapper = styled.div`
  margin-top: ${({ theme }) => theme.dimensions.spacing.default.top};
  padding-bottom: ${({ theme }) => theme.dimensions.spacing.default.bottom};

  overflow-x: auto;

  table {
    table-layout: auto;
    width: auto;
    min-width: ${({ theme }) => theme.dimensions.readableColumnWidth};
    padding-bottom: 0;
    margin-top: 0;
  }

  th {
    white-space: nowrap;
  }
`;

export default forwardRef(function Markdown(
  { md, className, components, style, css },
  ref
) {
  return (
    <div ref={ref}>
      <Wrapper style={style} css={css} className={className}>
        <ReactMarkdown
          children={md}
          remarkPlugins={[remarkGfm]}
          components={{
            code: CodeBlock,
            a: EmbeddedLinkRenderer,
            img: LightboxImageRenderer,
            table: (props) => (
              <TableWrapper className="table-wrapper">
                <table {...props} />
              </TableWrapper>
            ),
            p: ParagraphSkipImageAndEmbed, // avoid rendering imgs (now wrapped by div) within a paragraph which is the default
            ...components,
          }}
        />
      </Wrapper>
    </div>
  );
});
