import { Redirect, useHistory, useLocation } from 'react-router-dom';
import useAuth from '../hooks/use-auth';
import PageWrapper from '../components/PageWrapper';
import { toast } from 'react-toastify';
import { Login } from '../components/Auth/Login';
import React from 'react';

export default function LoginPage() {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const returnTo = (location.state as { from: string })?.from ?? undefined;
  const [email, setEmail] = React.useState('');

  const onGoToConfirmEmail = () => {
    history.push('/auth/confirm-email', {
      email,
      returnTo,
    });
  };

  if (isAuthenticated) {
    toast.error('You are logged in already');
    return <Redirect to="/" />;
  }

  return (
    <PageWrapper style={{ height: '100vh' }}>
      <Login
        title={"Log in to Enki's learning program"}
        subtitle={'Make sure to use the email address you signed up with'}
        goToConfirmEmail={onGoToConfirmEmail}
        redirectUri={`${window.location.origin}/auth-callback`}
        returnTo={returnTo}
        email={email}
        onEmailChange={(email) => setEmail(email)}
        shouldShowSignupLink
      />
    </PageWrapper>
  );
}
