import React from 'react';
import {
  ActivityContext,
  ActivityContextType,
} from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import FreeTextQuestion from './FreeText';
import SQLPlaygroundQuestion from './SQLPlaygroundQuestion';
import PythonPlaygroundQuestion from './PythonPlaygroundQuestion';

import { ActivityPayload } from '../../../hydra';
import FreeTextSQLPlaygroundQuestion from './FreeTextSQLPlaygroundQuestion';
import FreeTextSQLCodeQuestion from './FreeTextSQLCode';
import FreeTextPowerBIExternalQuestion from './FreeTextPowerBIExternal';
import FreeTextLookerEmbedQuestion from './FreeTextLookerEmbed';
import SheetsExternalQuestion from './SheetsExternal';
import LookerExternalQuestion from './LookerExternal';
import TableauExternalQuestion from './TableauExternal';
import FreeTextSheetsEmbedQuestion from './FreeTextSheetsEmbed';

export enum QUESTION_TYPE {
  FREE_TEXT = 'FREE_TEXT',
  SQL_PLAYGROUND = 'PLAYGROUND',
  FREE_TEXT_SQL_PLAYGROUND = 'FREE_TEXT_PLAYGROUND',
  PYTHON_PLAYGROUND = 'PYTHON_PLAYGROUND',
  FREE_TEXT_SQL_CODE = 'FREE_TEXT_SQL_CODE',
  FREE_TEXT_POWERBI_EXTERNAL = 'FREE_TEXT_POWERBI_EXTERNAL',
  FREE_TEXT_LOOKER_EMBED = 'FREE_TEXT_LOOKER_EMBED',
  FREE_TEXT_SHEETS_EMBED = 'FREE_TEXT_GOOGLE_SHEETS_EXTERNAL',
  SHEETS_EXTERNAL = 'GOOGLE_SHEETS_EXTERNAL',
  LOOKER_EXTERNAL = 'LOOKER_EXTERNAL',
  TABLEAU_EXTERNAL = 'TABLEAU_EXTERNAL',
}

export type QuestionProps = {
  question: ActivityPayload['exercise']['questions'][0];
  answer: ActivityContextType['submission']['questionSlugToAnswer'][string];
};

export default function ActivityQuestion() {
  const [activity] = React.useContext(ActivityContext);

  const currentStep = activity.currentStep;
  const question =
    currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity.exercise?.questions[currentStep?.questionIndex]
      : null;
  const answer =
    currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity.submission.questionSlugToAnswer[currentStep?.questionSlug]
      : null;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [question?.id]);

  if (!question || !answer) {
    // should not happen
    return null;
  }
  const questionProps = {
    key: question.id,
    question,
    answer,
  };

  if (question?.type === QUESTION_TYPE.FREE_TEXT) {
    return <FreeTextQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.SQL_PLAYGROUND) {
    return <SQLPlaygroundQuestion {...questionProps} />;
  }
  if (question?.type === QUESTION_TYPE.FREE_TEXT_SQL_PLAYGROUND) {
    return <FreeTextSQLPlaygroundQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.PYTHON_PLAYGROUND) {
    return <PythonPlaygroundQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.FREE_TEXT_SQL_CODE) {
    return <FreeTextSQLCodeQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.FREE_TEXT_POWERBI_EXTERNAL) {
    return <FreeTextPowerBIExternalQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.FREE_TEXT_LOOKER_EMBED) {
    return <FreeTextLookerEmbedQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.FREE_TEXT_SHEETS_EMBED) {
    return <FreeTextSheetsEmbedQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.SHEETS_EXTERNAL) {
    return <SheetsExternalQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.LOOKER_EXTERNAL) {
    return <LookerExternalQuestion {...questionProps} />;
  }

  if (question?.type === QUESTION_TYPE.TABLEAU_EXTERNAL) {
    return <TableauExternalQuestion {...questionProps} />;
  }

  return null;
}
