import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default class CodeBlock extends React.PureComponent {
  render() {
    const { inline, className, children, ...props } = this.props;

    if (inline) {
      return (
        <code {...props} className={className}>
          {children}
        </code>
      );
    }

    const languageMatches = /language-(\w+)/.exec(className || '') ?? [];

    const language = languageMatches ? languageMatches[1] : 'text';
    return (
      <SyntaxHighlighter
        language={language}
        style={atomOneDark}
        codeTagProps={{ style: { whiteSpace: 'pre' } }}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    );
  }
}
