import React from 'react';
import { useUpdateEffect } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

export default function usePythonRunner({ onOutput, onResult }) {
  const pyServiceWorkerRef = React.useRef(null);
  const pyWebWorkerRef = React.useRef(null);
  const [isWebWorkerReady, setIsWebWorkerReady] = React.useState(false);
  const [isServiceWorkerReady, setIsServiceWorkerReady] = React.useState(false);

  const [isReady, setIsReady] = React.useState(false);
  const [isRunning, setIsRunning] = React.useState(false);
  const [isAwaitingInput, setIsAwaitingInput] = React.useState(false);
  const playgroundId = React.useMemo(() => uuidv4(), []);

  const [output, setOutput] = React.useState('');

  const appendToOutput = React.useCallback(
    (data) => {
      setOutput((prevOutput) => prevOutput + data);
    },
    [setOutput]
  );

  useUpdateEffect(() => {
    if (!isRunning) {
      onResult(output);
    }
    // leaving out onResult, as this triggers an infinite loop
  }, [isRunning, output]);

  const initWebWorker = React.useCallback(() => {
    setIsWebWorkerReady(false);
    if (pyWebWorkerRef.current) {
      pyWebWorkerRef.current.terminate();
    }
    pyWebWorkerRef.current = new Worker(
      process.env.PUBLIC_URL + '/py-web-worker.js'
    );

    pyWebWorkerRef.current.onmessage = (event) => {
      if (event.data.type === 'stdout' || event.data.type === 'stderr') {
        onOutput(event?.data?.output);
        appendToOutput(event?.data?.output);
      } else if (event.data.type === 'ready') {
        pyWebWorkerRef.current.postMessage({
          type: 'register-playground-id',
          playgroundId,
        });
        setIsWebWorkerReady(true);
      } else if (event.data.type === 'error') {
        onOutput(event?.data?.output);
        appendToOutput(event?.data?.output);
        setIsRunning(false);
      } else if (event.data.type === 'finish-execution') {
        setIsRunning(false);
      }
    };
  }, [onOutput, playgroundId, appendToOutput]);

  const initServiceWorker = React.useCallback(async () => {
    setIsServiceWorkerReady(false);

    if ('serviceWorker' in navigator) {
      const swRegistration = await navigator.serviceWorker.register(
        process.env.PUBLIC_URL + '/py-service-worker.js'
      );
      const onServiceWorkerStateChange = () => {
        if (swRegistration.active?.state === 'activated') {
          pyServiceWorkerRef.current = swRegistration.active;
          setIsServiceWorkerReady(true);
        }
      };

      if (swRegistration.active) {
        onServiceWorkerStateChange();
      } else if (swRegistration.installing) {
        swRegistration.installing.addEventListener(
          'statechange',
          onServiceWorkerStateChange
        );
      } else if (swRegistration.waiting) {
        swRegistration.waiting.addEventListener(
          'statechange',
          onServiceWorkerStateChange
        );
      }

      navigator.serviceWorker.onmessage = (event) => {
        if (event.data.type === 'py-input-await') {
          if (event.data.playgroundId === playgroundId) {
            setIsAwaitingInput(true);
          }
        }
      };
    }
  }, [playgroundId]);

  const initPythonRunner = React.useCallback(async () => {
    await initServiceWorker();
    initWebWorker();

    return () => {
      if (pyWebWorkerRef.current) {
        pyWebWorkerRef.current.terminate();
        pyWebWorkerRef.current = null;
      }

      if (pyServiceWorkerRef.current) {
        pyServiceWorkerRef.current.unregister();
        pyServiceWorkerRef.current = null;
      }
    };
  }, [initServiceWorker, initWebWorker]);

  React.useEffect(() => {
    initPythonRunner();
  }, [initPythonRunner]);

  React.useEffect(() => {
    if (isWebWorkerReady && isServiceWorkerReady) {
      setIsReady(true);
    }
  }, [isWebWorkerReady, isServiceWorkerReady]);

  const runCode = React.useCallback(
    (code) => {
      if (isReady) {
        setOutput('');
        setIsRunning(true);
        pyWebWorkerRef.current.postMessage({ type: 'run-code', code });
      }
    },
    [isReady]
  );

  const sendInput = React.useCallback(
    (input) => {
      if (isReady) {
        pyServiceWorkerRef.current.postMessage({
          type: 'py-input-receive',
          value: input,
          playgroundId,
        });
        appendToOutput(input + '\n'); // emulate the stdout flush at the end of the input
        setIsAwaitingInput(false);
      }
    },
    [isReady, playgroundId, setIsAwaitingInput, appendToOutput]
  );

  const interruptExecution = React.useCallback(() => {
    if (isReady) {
      setIsAwaitingInput(false);
      setIsRunning(false);
      pyWebWorkerRef.current.terminate();
      pyWebWorkerRef.current = null;
      initWebWorker();
    }
  }, [isReady, initWebWorker]);

  return {
    isLoading: !isReady,
    isRunning,
    isAwaitingInput,
    runCode,
    sendInput,
    interruptExecution,
  };
}
