import React, { Dispatch } from 'react';
import isEmpty from 'lodash.isempty';
import { addActionBreadcrumb } from '../helpers';
import getDefaultOpenQuestionIndex from './helpers/get-default-open-question-index';
import isSubmissionDifferent from './helpers/is-submission-different';
import { ActivityPayload } from '../../hydra';
import sqlPlaygroundQueryChangeReducer, {
  ChangeQuerySqlPlaygroundQuestionActionType,
} from './reducers/sql-playground-change-query';
import sqlPlaygroundResultChangeReducer, {
  SetResultSqlPlaygroundQuestionActionType,
} from './reducers/sql-playground-set-result';
import {
  SetResultPythonPlaygroundQuestionActionType,
  pythonPlaygroundResultChangeReducer,
} from './reducers/python-playground-set-result';
import questionValueChangeReducer, {
  ChangeValueFreeTextGoogleSheetsExternalQuestionActionType,
  ChangeValueFreeTextPowerBiExternalQuestionActionType,
  ChangeValueFreeTextQuestionActionType,
  ChangeValueFreeTextSqlPlaygroundQuestionActionType,
  ChangeValueGoogleSheetsExternalQuestionActionType,
  ChangeValueQuestionFreeTextLookerEmbedActionType,
  ChangeValueFreeTextTableauEmbedQuestionActionType,
  ChangeValueLookerExternalQuestionActionType,
  ChangeValueTableauExternalQuestionActionType,
} from './reducers/question-change-value';
import sqlFreeTextPlaygroundQueryChangeReducer, {
  ChangeQueryFreeTextSqlPlaygroundQuestionActionType,
} from './reducers/free-text-sql-playground-change-query';
import sqlFreeTextPlaygroundResultChangeReducer, {
  SetResultFreeTextSqlPlaygroundQuestionActionType,
} from './reducers/free-text-sql-playground-set-result';
import pythonPlaygroundChangeValueReducer, {
  ChangeValuePythonPlaygroundQuestionActionType,
} from './reducers/python-playground-change-value';
import { ACTIVITY_STEP } from '../../components/ActivityPage/Content';
import getActivityInitialStep from './helpers/get-activity-step';

export type ActivityContextQuestionAnswer = {
  questionSlug: string;
  value: string;
  isAnswered: boolean;
  isCorrect: boolean | null;
  isRan: boolean | null;
  query?: string;
  result?: unknown; // this varies by question type
};

export type ActivityStepType =
  | {
      type: ACTIVITY_STEP.LESSON;
    }
  | {
      type: ACTIVITY_STEP.QUESTION;
      questionIndex: number;
      questionSlug: string;
    }
  | {
      type: ACTIVITY_STEP.FEEDBACK;
    }
  | null;

export type AIReview = {
  lesson_takeaways: string[];
  progress_suggestions: string | null;
  further_insights: string | null;
};

export type ActivityContextType = {
  id: number;
  title: string;
  slug: string;
  exercise: ActivityPayload['exercise'];
  content_id: number;
  mission_id: number | null;
  content_title: string;
  activity_session_id: number;
  latestSavedSubmission: null | {
    id: number;
    updated_at: string;
    created_at: string;
    difficulty_rating: number | null;
    feedback: string | null;
    seconds_spent: number | null;
    solutions: Array<string> | null;
    activity_session_id: number;
    ai_review: AIReview | null;
  };

  mentorReview: {
    hasMentor: boolean;
    sessionId: number;
    isReviewed: boolean;
    review: string | null;
    score: number | null;
    questionComments: string[] | null;
  };

  submission: {
    currentSubmissionStatus: string;
    difficultyRating: number | null;
    feedback: string;
    questionSlugToAnswer: Record<string, ActivityContextQuestionAnswer>;
    // isSubmitted: boolean;
  };

  pageFocusTimestampIntervals: Array<[Date, Date | undefined]>;
  didInteract: boolean;
  hasUnsavedWork: boolean;
  activeQuestionIndex: number;

  currentStep: ActivityStepType;
};

// const defaultDispatch: React.Dispatch<ActivityActionType> = () => {};

function getInitialState(): ActivityContextType {
  return {
    id: -1,
    title: '',
    slug: '',
    exercise: {
      id: -1,
      updated_at: '',
      created_at: '',
      description: '',
      lesson: '',
      difficulty: 0,
      activity_id: null,
      slug: '',
      questions: [],
    },
    content_id: -1,
    activity_session_id: -1,
    mission_id: null,
    content_title: '',
    latestSavedSubmission: null,
    mentorReview: {
      hasMentor: false,
      sessionId: 0,
      isReviewed: false,
      review: null,
      score: null,
      questionComments: null,
    },
    submission: {
      currentSubmissionStatus: 'INITIAL',
      difficultyRating: null,
      feedback: '',
      questionSlugToAnswer: {},
      // isSubmitted: false,
    },
    pageFocusTimestampIntervals: [],
    didInteract: false,
    hasUnsavedWork: false,
    activeQuestionIndex: 0,

    currentStep: null,
  };
}

export const ActivityContext = React.createContext<
  [ActivityContextType, Dispatch<ActivityActionType>]
>([getInitialState(), () => {}]);

export function ActivityProvider({
  activity,
  children,
}: {
  activity: ActivityPayload;
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(
    activityReducer,
    initialStateActivity(activity)
  );

  return (
    <ActivityContext.Provider value={[state, dispatch]}>
      {children}
    </ActivityContext.Provider>
  );
}

export const initialStateActivity = (
  activity: ActivityPayload
): ActivityContextType => {
  return {
    id: activity.id,
    title: activity.title,
    slug: activity.slug,
    exercise: activity.exercise,
    content_id: activity.content_id,
    mission_id: activity.mission_id,
    content_title: activity.content_title,
    latestSavedSubmission: activity.latest_submission,
    mentorReview: {
      hasMentor: activity.has_mentor,
      sessionId: activity.activity_session_id,
      isReviewed:
        !!activity.activity_session_instructor_review ||
        !!activity.activity_session_instructor_score,
      review: activity.activity_session_instructor_review,
      score: activity.activity_session_instructor_score,
      questionComments: activity.activity_session_instructor_question_comments,
    },
    activity_session_id: activity.activity_session_id,
    submission: {
      currentSubmissionStatus: 'INITIAL',
      difficultyRating: activity?.latest_submission?.difficulty_rating ?? null,
      feedback: activity?.latest_submission?.feedback ?? '',
      questionSlugToAnswer: initialQuestionsState(activity),
    },
    pageFocusTimestampIntervals: [],
    didInteract: false,
    hasUnsavedWork: false,
    activeQuestionIndex: getDefaultOpenQuestionIndex(activity),
    currentStep: getActivityInitialStep(activity),
  };
};

// For each question, the `value` field is what gets submitted as the solution.
// For playground related questions, the current value of the query string is
// stored in `query` and that value is put into `value` field when the query is ran.
// For the free text question, the `value` is updated on change.
const initialQuestionsState = (activity: ActivityPayload) => {
  const isSubmitted = !isEmpty(activity.latest_submission);

  return (activity?.exercise?.questions ?? []).reduce(
    (hash, question, i) => {
      const solution = activity?.latest_submission?.solutions?.[i] ?? null;
      const answerState =
        activity?.latest_submission?.answer_states?.[question.slug] ?? null;

      if (
        [
          'FREE_TEXT',
          'LOOKER_EXTERNAL',
          'FREE_TEXT_LOOKER_EMBED',
          'TABLEAU_EXTERNAL',
          'FREE_TEXT_TABLEAU_EMBED',
          'GOOGLE_SHEETS_EXTERNAL',
          'FREE_TEXT_GOOGLE_SHEETS_EXTERNAL',
          'FREE_TEXT_POWERBI_EXTERNAL',
          'FREE_TEXT_SQL_CODE',
        ].includes(question.type)
      ) {
        hash[question.slug] = {
          questionSlug: question.slug,
          value: solution ?? '',
          isAnswered: isSubmitted ? !!solution : false,
          isCorrect: null,
          isRan: null,
        };
      }

      if (question.type === 'PYTHON_PLAYGROUND') {
        hash[question.slug] = {
          questionSlug: question.slug,
          value: (solution || question.initial_py) ?? '',
          result: null,
          isAnswered: isSubmitted ? !!solution : false,
          isCorrect: answerState?.is_correct ?? null,
          isRan: null,
        };
      }

      if (question.type === 'PLAYGROUND') {
        hash[question.slug] = {
          questionSlug: question.slug,
          value: solution ?? '', // this is what gets submitted
          query: solution ?? '',
          result: { type: 'EMPTY' },
          isAnswered: isSubmitted ? !!solution : false,
          isCorrect: answerState?.is_correct ?? null,
          isRan: null,
        };
      }

      if (question.type === 'FREE_TEXT_PLAYGROUND') {
        hash[question.slug] = {
          questionSlug: question.slug,
          value: solution ?? '', // this should be the free text answer
          query: '', // we don't save these
          result: { type: 'EMPTY' },
          isAnswered: isSubmitted ? !!solution : false,
          isCorrect: answerState?.is_correct ?? null,
          isRan: null,
        };
      }
      return hash;
    },
    {} as Record<
      string,
      {
        questionSlug: string;
        value: string;
        isAnswered: boolean;
        isCorrect: boolean | null;
        query?: string;
        result?: unknown; // this varies by question type
        isRan: boolean | null;
      }
    >
  );
};

export const ACTIVITY_ACTION = {
  RECORD_VISIBILITY_CHANGE: 'RECORD_VISIBILITY_CHANGE' as const,
  SET_SUBMISSION_STATUS: 'SET_SUBMISSION_STATUS' as const,
  SET_DIFFICULTY: 'SET_DIFFICULTY' as const,
  SET_FEEDBACK: 'SET_FEEDBACK' as const,
  SET_ACTIVE_QUESTION_INDEX: 'SET_ACTIVE_QUESTION_INDEX' as const,
  CHANGE_VALUE_FREE_TEXT_SQL_PLAYGROUND_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_SQL_PLAYGROUND_QUESTION' as const,
  CHANGE_QUERY_FREE_TEXT_SQL_PLAYGROUND_QUESTION:
    'CHANGE_QUERY_FREE_TEXT_SQL_PLAYGROUND_QUESTION' as const,
  SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION:
    'SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION' as const,
  CHANGE_QUERY_SQL_PLAYGROUND_QUESTION:
    'CHANGE_QUERY_SQL_PLAYGROUND_QUESTION' as const,
  SET_RESULT_SQL_PLAYGROUND_QUESTION:
    'SET_RESULT_SQL_PLAYGROUND_QUESTION' as const,
  CHANGE_VALUE_TABLEAU_EXTERNAL_QUESTION:
    'CHANGE_VALUE_TABLEAU_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_LOOKER_EXTERNAL_QUESTION:
    'CHANGE_VALUE_LOOKER_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_GOOGLE_SHEETS_EXTERNAL_QUESTION:
    'CHANGE_VALUE_GOOGLE_SHEETS_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_FREE_TEXT_POWERBI_EXTERNAL_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_POWERBI_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_FREE_TEXT_QUESTION: 'CHANGE_VALUE_FREE_TEXT_QUESTION' as const,
  CHANGE_VALUE_FREE_TEXT_TABLEAU_EMBED_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_TABLEAU_EMBED_QUESTION' as const,
  CHANGE_VALUE_FREE_TEXT_GOOGLE_SHEETS_EXTERNAL_QUESTION:
    'CHANGE_VALUE_FREE_TEXT_GOOGLE_SHEETS_EXTERNAL_QUESTION' as const,
  CHANGE_VALUE_QUESTION_FREE_TEXT_LOOKER_EMBED:
    'CHANGE_VALUE_QUESTION_FREE_TEXT_LOOKER_EMBED' as const,
  CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION:
    'CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION' as const,
  SET_RESULT_PYTHON_PLAYGROUND_QUESTION:
    'SET_RESULT_PYTHON_PLAYGROUND_QUESTION' as const,
  SET_PAGE_STEP: 'SET_PAGE_STEP' as const,
  REFRESH_SUBMISSION: 'REFRESH_SUBMISSION' as const,
  REFRESH_AI_REVIEW: 'REFRESH_AI_REVIEW' as const,
};
type ActivityActionType =
  | RecordVisibilityActionType
  | SetSubmissionStatusActionType
  | SetDifficultyActionType
  | SetFeedbackActionType
  | SetActiveQuestionIndexActionType
  | ChangeValueFreeTextGoogleSheetsExternalQuestionActionType
  | ChangeValueLookerExternalQuestionActionType
  | ChangeValueGoogleSheetsExternalQuestionActionType
  | ChangeValueFreeTextPowerBiExternalQuestionActionType
  | ChangeValueFreeTextQuestionActionType
  | ChangeValueFreeTextTableauEmbedQuestionActionType
  | ChangeValueFreeTextGoogleSheetsExternalQuestionActionType
  | ChangeValueQuestionFreeTextLookerEmbedActionType
  | ChangeValuePythonPlaygroundQuestionActionType
  | ChangeValueFreeTextSqlPlaygroundQuestionActionType
  | ChangeValueTableauExternalQuestionActionType
  | SetResultSqlPlaygroundQuestionActionType
  | SetResultPythonPlaygroundQuestionActionType
  | ChangeQuerySqlPlaygroundQuestionActionType
  | ChangeQueryFreeTextSqlPlaygroundQuestionActionType
  | SetResultPythonPlaygroundQuestionActionType
  | SetResultFreeTextSqlPlaygroundQuestionActionType
  | SetPageStep
  | RefreshSubmissionActionType;

function activityReducer(
  state: ActivityContextType,
  action: ActivityActionType
): ActivityContextType {
  addActionBreadcrumb({
    context: 'activity-submission',
    actionType: action.type,
  });

  switch (action.type) {
    case ACTIVITY_ACTION.CHANGE_QUERY_SQL_PLAYGROUND_QUESTION: {
      return sqlPlaygroundQueryChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_RESULT_SQL_PLAYGROUND_QUESTION: {
      return sqlPlaygroundResultChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.RECORD_VISIBILITY_CHANGE:
      return activityVisibilityReducer(state, action);
    case ACTIVITY_ACTION.SET_SUBMISSION_STATUS: {
      return activitySetSubmissionStatusReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_DIFFICULTY: {
      return activitySetDifficultyReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_FEEDBACK: {
      return activitySetFeedbackReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_ACTIVE_QUESTION_INDEX: {
      return activitySetActiveQuestionIndexReducer(state, action);
    }

    case ACTIVITY_ACTION.CHANGE_VALUE_PYTHON_PLAYGROUND_QUESTION: {
      return pythonPlaygroundChangeValueReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_RESULT_PYTHON_PLAYGROUND_QUESTION: {
      return pythonPlaygroundResultChangeReducer(state, action);
    }

    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_SQL_PLAYGROUND_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_QUERY_FREE_TEXT_SQL_PLAYGROUND_QUESTION: {
      return sqlFreeTextPlaygroundQueryChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_RESULT_FREE_TEXT_SQL_PLAYGROUND_QUESTION: {
      return sqlFreeTextPlaygroundResultChangeReducer(state, action);
    }

    case ACTIVITY_ACTION.CHANGE_VALUE_TABLEAU_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_LOOKER_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_GOOGLE_SHEETS_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_POWERBI_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_TABLEAU_EMBED_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_GOOGLE_SHEETS_EXTERNAL_QUESTION: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.CHANGE_VALUE_QUESTION_FREE_TEXT_LOOKER_EMBED: {
      return questionValueChangeReducer(state, action);
    }
    case ACTIVITY_ACTION.SET_PAGE_STEP:
      return setPageStepReducer(state, action);
    case ACTIVITY_ACTION.REFRESH_SUBMISSION:
      return refreshSubmissionReducer(state, action);
    default:
      throw Error(`Invalid action type: "${action}`);
  }
}

type RecordVisibilityActionType = {
  type: typeof ACTIVITY_ACTION.RECORD_VISIBILITY_CHANGE;
  payload: {
    isIdle: boolean;
  };
};
function activityVisibilityReducer(
  state: ActivityContextType,
  action: RecordVisibilityActionType
): ActivityContextType {
  const pageFocusTimestampIntervals = state.pageFocusTimestampIntervals;
  const isIdle = action.payload.isIdle;

  if (!isIdle) {
    return {
      ...state,
      pageFocusTimestampIntervals: [
        ...pageFocusTimestampIntervals,
        [new Date(), undefined],
      ],
    };
  }
  const existingIntervals = pageFocusTimestampIntervals.slice(0, -1);
  const lastInterval = pageFocusTimestampIntervals[
    pageFocusTimestampIntervals.length - 1
  ] || [new Date(), undefined];
  return {
    ...state,
    pageFocusTimestampIntervals: [
      ...existingIntervals,
      [lastInterval[0], new Date()],
    ],
  };
}

type SetSubmissionStatusActionType = {
  type: typeof ACTIVITY_ACTION.SET_SUBMISSION_STATUS;
  payload: {
    currentSubmissionStatus: string;
  };
};
function activitySetSubmissionStatusReducer(
  state: ActivityContextType,
  action: SetSubmissionStatusActionType
) {
  return {
    ...state,
    submission: {
      ...state.submission,
      currentSubmissionStatus: action.payload.currentSubmissionStatus,
      isSubmitted: action.payload.currentSubmissionStatus === 'SUCCESS',
    },
  };
}

type SetFeedbackActionType = {
  type: typeof ACTIVITY_ACTION.SET_FEEDBACK;
  payload: {
    feedback: string;
  };
};
function activitySetFeedbackReducer(
  state: ActivityContextType,
  action: SetFeedbackActionType
) {
  const submission = {
    ...state.submission,
    feedback: action.payload.feedback,
  };
  return {
    ...state,
    submission,
    didInteract: true,
    hasUnsavedWork: isSubmissionDifferent(
      submission,
      state.latestSavedSubmission,
      state.exercise.questions
    ),
  };
}

type SetDifficultyActionType = {
  type: typeof ACTIVITY_ACTION.SET_DIFFICULTY;
  payload: {
    difficultyRating: number;
  };
};
function activitySetDifficultyReducer(
  state: ActivityContextType,
  action: SetDifficultyActionType
) {
  const submission = {
    ...state.submission,
    difficultyRating: action.payload.difficultyRating,
  };
  return {
    ...state,
    submission,
    didInteract: true,
    hasUnsavedWork: isSubmissionDifferent(
      submission,
      state.latestSavedSubmission,
      state.exercise.questions
    ),
  };
}

type SetActiveQuestionIndexActionType = {
  type: typeof ACTIVITY_ACTION.SET_ACTIVE_QUESTION_INDEX;
  payload: {
    index: number;
  };
};
function activitySetActiveQuestionIndexReducer(
  state: ActivityContextType,
  action: SetActiveQuestionIndexActionType
) {
  return {
    ...state,
    didInteract: true,
    activeQuestionIndex: action.payload.index,
  };
}

type SetPageStep = {
  type: typeof ACTIVITY_ACTION.SET_PAGE_STEP;
  payload: {
    step: ActivityContextType['currentStep'];
  };
};
function setPageStepReducer(
  state: ActivityContextType,
  action: SetPageStep
): ActivityContextType {
  return {
    ...state,
    currentStep: action.payload.step,
  };
}

type RefreshSubmissionActionType = {
  type: typeof ACTIVITY_ACTION.REFRESH_SUBMISSION;
  payload: {
    latestSubmission: ActivityContextType['latestSavedSubmission'];
  };
};

function refreshSubmissionReducer(
  state: ActivityContextType,
  action: RefreshSubmissionActionType
): ActivityContextType {
  return {
    ...state,
    latestSavedSubmission: action.payload.latestSubmission,
    didInteract: true,
    hasUnsavedWork: false,
  };
}
