import { H1 } from '../../elements/Text';
import { QuestionProps } from '.';
import React from 'react';
import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import { BottomSpacer, FullScreenQuestion, getQuestionText } from './common';
import { MultiLineInput } from '../../elements/Input';
import { ReadableContainer, ReadableMarkdown } from '../Containers';
import MentorReview from '../MentorComment';

export default function FreeTextQuestion({ question, answer }: QuestionProps) {
  const [activity, dispatch] = React.useContext(ActivityContext);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const onFreeTextQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_FREE_TEXT_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <FullScreenQuestion>
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      {/* @ts-expect-error - Markdown component is not typed properly */}
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <MultiLineInput
          maxRows={10}
          value={answer.value ?? ''}
          placeholder="Type your answer here"
          onChange={(e) => onFreeTextQuestionTextChange(e.target.value)}
        />
      </ReadableContainer>
      <BottomSpacer />
    </FullScreenQuestion>
  );
}
