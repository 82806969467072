import { H1, H4, PExtraSmall, PSmall } from '../../elements/Text';
import { QuestionProps } from '.';
import React from 'react';
import { ACTIVITY_ACTION, ActivityContext } from '../../../contexts/activity';
import { ACTIVITY_STEP } from '../Content';
import {
  BottomSpacer,
  ButtonsRow,
  FullScreenQuestion,
  getQuestionText,
} from './common';
import { ReadableContainer, ReadableMarkdown } from '../Containers';

import Button from '../../elements/Button';
import styled, { useTheme } from 'styled-components';
import Modal from '../../Modal/Modal';
import { CloseIcon } from '../../Icons';
import LookerEmbed from '../../Embeds/LookerEmbed';
import { Input } from '../../elements/Input';
import MentorReview from '../MentorComment';

const LOOKER_REGEX = /https:\/\/.*\.looker.com\/.*/;

function embedLookerLinkIfNeeded(url: string) {
  if (url.includes('embed')) {
    return url;
  }
  return url.replace('looker.com', 'looker.com/embed');
}

export default function LookerExternalQuestion({
  question,
  answer,
}: QuestionProps) {
  const theme = useTheme();
  const [activity, dispatch] = React.useContext(ActivityContext);
  const qIndex =
    activity.currentStep?.type === ACTIVITY_STEP.QUESTION
      ? activity?.currentStep?.questionIndex
      : -1;

  const link = question?.looker_link ?? '';

  const [isLookerURLValid, setIsLookerURLValid] = React.useState(true);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!answer.value) {
      setIsLookerURLValid(true);
    } else {
      setIsLookerURLValid(!!answer.value.match(LOOKER_REGEX));
    }
  }, [answer.value]);

  const onLookerExternalQuestionTextChange = (value: string) => {
    dispatch({
      type: ACTIVITY_ACTION.CHANGE_VALUE_LOOKER_EXTERNAL_QUESTION,
      payload: {
        question,
        value,
      },
    });
  };

  const mentorComment = (activity?.mentorReview?.questionComments ?? [])[
    qIndex
  ];

  return (
    <FullScreenQuestion>
      {isPreviewModalOpen && (
        <LookerPreviewModal
          url={embedLookerLinkIfNeeded(answer.value)}
          onCloseModal={() => setIsPreviewModalOpen(false)}
        />
      )}
      <ReadableContainer>
        <H1>Exercise {qIndex + 1}</H1>
        {!!mentorComment && <MentorReview comment={mentorComment} />}
      </ReadableContainer>
      {/* @ts-expect-error - Markdown component is not typed properly */}
      <ReadableMarkdown md={getQuestionText(question)} />
      <ReadableContainer>
        <PExtraSmall>
          Open the Looker playground in a new tab, carry out your work and copy
          its URL in the box below. Once you're done, you'll be able to preview
          and verify your answer.
        </PExtraSmall>
        <ButtonsRow>
          <Button
            type="secondary"
            label="Open Looker Playground"
            faIcon="fas fa-chart-pie"
            onClick={() => window.open(link, '_blank')}
          />
          <Button
            type="default"
            label="Preview Answer"
            isDisabled={!answer?.value || !isLookerURLValid}
            faIcon="fas fa-search"
            onClick={() => setIsPreviewModalOpen(true)}
            color={
              !answer?.value || !isLookerURLValid
                ? theme.colors.textRegular
                : theme.colors.buttonPrimary
            }
          />
        </ButtonsRow>
        <Input
          type="link"
          placeholder={'Enter your answer here'}
          value={answer.value}
          onChange={(e) => onLookerExternalQuestionTextChange(e.target.value)}
        />
      </ReadableContainer>
      <BottomSpacer />
    </FullScreenQuestion>
  );
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 36px;

  > * {
    margin: 0;
  }
`;

function LookerPreviewModal({
  url,
  onCloseModal,
}: {
  url: string;
  onCloseModal: () => void;
}) {
  const theme = useTheme();
  return (
    <Modal
      style={{ width: '100%', maxWidth: theme.dimensions.readableColumnWidth }}
    >
      <Row>
        <H4>Looker Answer Preview</H4>
        <CloseIcon
          style={{ padding: 0, alignSelf: 'flex-start' }}
          onClick={onCloseModal}
        />
      </Row>
      <PSmall>
        Here is a preview of your answer. Make sure that this reflects your
        intended submission.
      </PSmall>
      <LookerEmbed
        height={''}
        isClickable={false}
        url={url}
        id="looker-answer-preview"
      />

      <div style={{ height: '52px' }} />
    </Modal>
  );
}
