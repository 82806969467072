import isEqualWith from 'lodash.isequalwith';

type TableResult = Array<{
  columns: string[];
  values: Array<Array<number | string | boolean>>;
}>;

type EmptyResult = {
  type: 'EMPTY';
  value: undefined;
};

type ErrorResult = {
  type: 'ERROR';
  value: string;
};

type QueryResult = {
  type: 'QUERY';
  value: TableResult;
};

export type SQLResult = EmptyResult | QueryResult | ErrorResult;

export type SQLSchemasType = Array<{
  name: string;
  schema: {
    columns: string[];
    values: string[][];
  };
}>;

const floatCustomizer = (obj1: number, obj2: number) => {
  if (!Number.isFinite(obj1) || !Number.isFinite(obj2)) {
    // allows isEqualWith to fallback to its own deep comparison
    return undefined;
  }

  /**
   * SQLlite doc: REAL. The value is a floating point value, stored as an 8-byte (64-bit) IEEE floating point number.
   * ECMASCRIPT: primitive value corresponding to a double-precision 64-bit binary format IEEE 754 value.
   *
   * Given that they are the same, we should be able to do a simple < Number.EPSILON
   */
  return Number.isInteger(obj1) && Number.isInteger(obj2)
    ? obj1 === obj2
    : Math.abs(obj1 - obj2) < Number.EPSILON ||
        // Extra protection, compare up to 8 decimals
        obj1.toFixed(8) === obj2.toFixed(8);
};

const extractColumnOrderAndCaseInvariantTable = (table: TableResult[0]) => ({
  ...table,
  columns: table.columns
    .map((c) => c.toLowerCase())
    .slice()
    .sort(),
  values: table.values.map((row) =>
    row
      .map((value, index) => ({ value, index }))
      .sort((a, b) =>
        table.columns[a.index]
          .toLowerCase()
          .localeCompare(table.columns[b.index].toLowerCase())
      )
      .map(({ value }) => value)
  ),
});

export default function isSQLPlaygroundQuestionCorrect({
  isDQLQuery,
  result,
  verificationResult,
}: {
  isDQLQuery: boolean;
  result: SQLResult;
  verificationResult: SQLResult;
}) {
  if (
    verificationResult?.type === 'ERROR' ||
    verificationResult.type === 'EMPTY'
  ) {
    return false;
  }

  if (result.type === 'ERROR' || result.type === 'EMPTY') {
    return false;
  }

  if (!isDQLQuery) {
    return verificationResult?.value?.[0]?.values?.[0]?.['0'] === 1;
  }


  const [
    userResult = {
      columns: [],
      values: [],
    },
  ] = result.value;
  const [
    expectedResult = {
      columns: [],
      values: [],
    },
  ] = verificationResult.value;

  const invariantUserTable =
    extractColumnOrderAndCaseInvariantTable(userResult);
  const invariantExpectedTable =
    extractColumnOrderAndCaseInvariantTable(expectedResult);

  if (
    isEqualWith(invariantExpectedTable, invariantUserTable, floatCustomizer)
  ) {
    return true;
  }

  return false;
}
